.testocassandra {
  position: absolute;
  top: 20%;
  right: -21%;
  left: 100%;

  animation: fadeIn 10s;
  font-weight: 100;
  text-align: justify;
  font-size: 70%;
}

.gallery31 a {
  position: relative;
  left: 750%;
  top: 90%;
  white-space: nowrap;
  border: 1px;
  border-style: solid;
  text-decoration: none;
  background: none;
  color: #ffffff;
  border-color: #212121;
  font-weight: 200;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px 7px;
}

@media screen and (max-width: 500px) {
  .testocassandra {
    font-size: 50%;
    font-weight: 100;
    opacity: 1;
    position: absolute;
    padding-bottom: 5px;
    left: 2900px;
    top: 200px;
    padding-right: 400px;
    text-align: justify;

    animation: fadeIn 10s;
  }


.gallery31 a {
  position: relative;
  left: 570%;
  top: 100%;
  white-space: nowrap;
  border: 1px;
  border-style: solid;
  text-decoration: none;
  background: none;
  color: #ffffff;
  border-color: #212121;
  font-weight: 200;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px 7px;
}
}
