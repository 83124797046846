
.venti {
  position: absolute;
top: 350px;
left: 15%;
}


.venticurriculum {
  position: absolute;
top: 350px;
left: 19%;
}


.ventidue {
  position: absolute;
top: 350px;
left:33%;
}


.ventiduecurriculum {
  position: absolute;
top: 350px;
left:37%;
}

.ventitre {
  position: absolute;
top: 350px;
left:60%;
}


.ventitrecurriculum {
  position: absolute;
top: 350px;
left:64%;
}

.ventiquattro {
  position: absolute;
top: 350px;
left:90%;
}


.ventiquattrocurriculum {
  position: absolute;
top: 350px;
left:94%;
min-width: 1000px;
}


.bottonedietro a {
  position: relative;
  left: 3220%;
  top: 390px;
  white-space: nowrap;
  border: 1px;
  border-style: solid;
  text-decoration: none;
  background: none;
  color: #ffffff;
  border-color: #212121;
  font-weight: 200;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px 7px;
}
