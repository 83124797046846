@import url('https://fonts.cdnfonts.com/css/tt-commons');

.body {
  font-family: "TT Commons", sans-serif;
  color: #2d3e50;
}
.section {
  max-width: 100%;
  margin: auto;
  text-align: center;
}
.videocontain {
  height: 100%;
  display: flex;
  align-items: center;
  color: #242424;
  flex-direction: column;
  align-items: center;
}
.video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-wrap video {
  min-width: 100%;
  min-height: 100%;
}
.overlay {
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #242424;
  position: absolute;
  opacity: 0.7;
}
.landing-text {
  z-index: 2;
}

.enterbtn {
  color: #fff;
  font-size: 200%;
  background: none;
  border-radius: 5px;
  position: fixed;
  top: 40%;
  right: 45%;
  text-decoration: none;
}

.a.enterbtn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.a.enterbtn:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

@media screen and (max-width: 650px) {
  .video-wrap video {
    width: 100% !important;
    left: 0 !important;
    height: auto !important;
  }

  .overlay {
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #242424;
    position: absolute;
    opacity: 0.7;
  }
  .landing-text {
    z-index: 2;
  }

  .enterbtn {
    color: #fff;
    font-size: 150%;
    background: none;
    border-radius: 5px;
    position: fixed;
    top: 47%;
    right: 45%;
    text-decoration: none;
  }

  .a.enterbtn::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  .a.enterbtn:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}



