.operecontainerin {
  display: flex;
  flex-direction: row;

  scroll-behavior: smooth;
  min-width: 3000px;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}

.operecontainer {
  height: 1000vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: hidden !important;
  overflow-x: scroll;
  position: absolute;
  left: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.divisione1 {
  position: absolute;
  top: 77%;
  right: 91%;
  font-size: 200%;
  color: #ffffff;
}

.divisione2 {
  position: absolute;
  top: 77%;
  right: 19%;
  font-size: 200%;
  color: #ffffff;
}

.gallery2 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery3 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery4 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}
.gallery5 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery6 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery7 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery8 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery9 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;
  white-space: nowrap;
  animation: fadeIn 5s;
}

.gallery10 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 150%;

  animation: fadeIn 5s;
}

.gallery11 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 330%;

  animation: fadeIn 5s;
}

.gallery12 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 330%;

  animation: fadeIn 5s;
}

.gallery13 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 330%;

  animation: fadeIn 5s;
}

.gallery14 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 330%;

  animation: fadeIn 5s;
}

.gallery15 img {
  object-fit: contain;
  width: 300px;
  padding: 13px;
  position: relative;
  top: 20%;
  left: 330%;

  animation: fadeIn 5s;
}

.gallery21 a {
  position: relative;
  left: 950%;
  top: 60%;
  white-space: nowrap;
  border: 1px;
  border-style: solid;
  text-decoration: none;
  background: none;
  color: #ffffff;
  border-color: #212121;
  font-weight: 200;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px 7px;
}

.desc {
  padding: 15px;
  text-align: center;
  position: relative;
  top: 20%;
  left: 150%;
  color: #ffffff;
  animation: fadeIn 5s;
}

.desc2 {
  padding: 15px;
  text-align: center;
  position: relative;
  top: 20%;
  left: 330%;
  color: #ffffff;
  animation: fadeIn 5s;
}

.testoRitratti {
  position: absolute;
  left: 20%;
  top: 50%;
  animation: fadeIn 4s;
}

@media screen and (max-width: 481px) {
  .operecontainer {
    width: 100vh;
    height: 5700px;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: hidden !important;
    overflow-x: scroll;
    position: absolute;
    left: 100px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .divisione1 {
    position: absolute;
    top: 67%;
    right: 89%;
    font-size: 200%;
    color: #ffffff;
  }

  .divisione2 {
    position: absolute;
    top: 67%;
    right: -16%;
    font-size: 200%;
    color: #ffffff;
  }


   .gallery2 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery3 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery4 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }
  .gallery5 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery6 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery7 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery8 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery9 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;
    white-space: nowrap;
    animation: fadeIn 5s;
  }

  .gallery10 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 150%;

    animation: fadeIn 5s;
  }

  .gallery11 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 330%;

    animation: fadeIn 5s;
  }

  .gallery12 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 330%;

    animation: fadeIn 5s;
  }

  .gallery13 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 330%;

    animation: fadeIn 5s;
  }

  .gallery14 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 330%;

    animation: fadeIn 5s;
  }

  .gallery15 img {
    object-fit: contain;
    width: 300px;
    padding: 13px;
    position: relative;
    top: 40%;
    left: 330%;

    animation: fadeIn 5s;
  }

  .gallery21 a {
    position: relative;
    left: 950%;
    top: 70%;
    white-space: nowrap;
    border: 1px;
    border-style: solid;
    text-decoration: none;
    background: none;
    color: #ffffff;
    border-color: #212121;
    font-weight: 200;
    cursor: pointer;
    border-radius: 50%;
    padding: 7px 7px;
  }

  .desc {
    padding: 15px;
    text-align: center;
    position: relative;
    top: 37%;
    left: 150%;
    color: #ffffff;
    animation: fadeIn 5s;
  }

  .desc2 {
    padding: 15px;
    text-align: center;
    position: relative;
    top: 37%;
    left: 330%;
    color: #ffffff;
    animation: fadeIn 5s;
  }

  .testoRitratti {
    position: absolute;
    left: 20%;
    top: 50%;
    animation: fadeIn 4s;
  }
}
