.testoartemisia {
  position: absolute;
 
  font-size: 50%;
  font-weight: normal;
   padding-top: 1%;
    padding-left: 30%;
      padding-right: 60%;
  text-align: justify;
}
@media screen and (max-width: 481px) {
  .testoartemisia {
   opacity: 0.5;
text-align: justify;
    position: absolute;
    padding-top: 1%;
    padding-left: 1%;
      padding-right: 60%;
    font-size: 40%;
    font-weight: normal;

   left: 30%;
    color: #666666;
  
    
  } 
 
} 
