@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero {
  width: 100%;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-x: scroll;
  overflow-y: hidden !important;
  position: absolute;
  top: -15%;
  left: 27%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 30;
  transition: all $time ease(gravity);
  white-space: nowrap;
  animation: fadeIn 5s;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

.content {
  display: flex;
  flex-direction: row;
  min-width: 2000px;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  z-index: 30;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.slide {
  width: 50vw;
  height: 30vh;
}

.slideone {
  width: 50vw;
  height: 30vh;
}

.slideoneoverlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  color: #ffffff;
  font: 30px sans-serif;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.9s ease;
  background-color: #242424;
  padding-top: 100px;
  border-radius: 10px;
  font-size: 80%;
  writing-mode: vertical-lr;
}
.slideoneoverlay h1 {
  position: relative;
  right: -20%;
  top: -5%;
  opacity: 1;
  font-weight: 400;
}
.slideoneoverlay:hover {
  opacity: 0.5;
}

.slidethree {
  width: 50vw;
  height: 30vh;
}

.slidethreeoverlay {
  position: absolute;
  top: 25%;
  left: 0;
  box-sizing: border-box;
  width: 50vw;
  height: 30vh;
  color: #ffffff;
  font: 30px sans-serif;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.9s ease;
  background-color: #242424;
  padding-top: 100px;
  border-radius: 10px;
  font-size: 80%;
  writing-mode: vertical-lr;
}
.slidethreeoverlay h1 {
  position: relative;
  right: -40%;
  top: -30%;
  opacity: 1;
  font-weight: 400;
}
.slidethreeoverlay:hover {
  opacity: 0.8;
}

.slidefour {
  width: 50vw;
  height: 30vh;
}

.slidefouroverlay {
  position: absolute;
  top: 50%;
  left: 0;
  box-sizing: border-box;
  width: 50vw;
  height: 30vh;
  color: #ffffff;
  font: 30px sans-serif;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.9s ease;
  background-color: #242424;
  padding-top: 100px;
  border-radius: 10px;
  font-size: 80%;
  writing-mode: vertical-lr;
}
.slidefouroverlay h1 {
  position: relative;
  right: -44%;
  top: -20%;
  opacity: 1;
  font-weight: 400;
}
.slidefouroverlay:hover {
  opacity: 0.6;
}

.slidefive {
  width: 50vw;
  height: 30vh;
}
/*
  .slidefiveoverlay {
    position: absolute;
    top: 80%;
    left: 0;
    box-sizing: border-box;
    width: 50vw;
    height: 30vh;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidefiveoverlay h1 {
    object-fit: cover;
    position: relative;
    right: -44%;
    top: -20%;
    opacity: 1;
            font-weight: 400;

  }
  .slidefiveoverlay:hover {
    opacity: 0.6;
  }
*/
.slidefiveoverlay {
  display: none;
}
.slideone img {
  width: 29vh;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}

.slidetwo img {
  width: 29vh;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}
.slidethree img {
  width: 29vh;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}
.slidefour img {
  width: 29vh;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  position: absolute;
}

.slidefive img {
  display: none;
}
/*
  .slidefive img {
    width: 29vh;
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
*/

@media screen and (max-width: 500px) {
  .hero {
    width: 100vh;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-x: auto;
    overflow-y: hidden !important;
    position: absolute;
    top: -12%;
    left: 20%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: 30;
    transition: all $time ease(gravity);
    white-space: nowrap;
    animation: fadeIn 5s;
    -webkit-overflow-scrolling: touch;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: row;
    min-width: 1025;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
    z-index: 30;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .slide {
    width: 50vw;
    height: 30vh;
  }

  .slideone {
    width: 50vw;
    height: 30vh;
  }

  .slideoneoverlay {
    position: absolute;
    top: 0;
    left: 30%;
    box-sizing: border-box;
    width: 7%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.9s ease;
    background-color: transparent;

    font-size: 80%;
    writing-mode: vertical-lr;
  }

  .slideoneoverlay h1 {
    position: relative;
    right: 70%;
    top: -20%;
    opacity: 1;
    font-weight: 400;
  }
  .slideoneoverlay:hover {
    opacity: 0.7;
  }

  .slidetwo {
    width: 50vw;
    height: 30vh;
  }

  .slidetwooverlay {
    position: absolute;
    top: 20%;
    left: 8%;
    box-sizing: border-box;
    width: 7%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.9s ease;
    background-color: transparent;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidetwooverlay h1 {
    position: relative;
    right: -30%;
    top: -50%;
    opacity: 1;
    font-weight: 400;
  }
  .slidetwooverlay:hover {
    opacity: 0.8;
  }

  .slidethree {
    width: 50vw;
    height: 30vh;
  }

  .slidethreeoverlay {
    position: absolute;
    top: 40%;
    left: 8%;
    box-sizing: border-box;
    width: 7%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.9s ease;
    background-color: transparent;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidethreeoverlay h1 {
    position: relative;
    right: -200%;
    top: -170%;
    opacity: 1;
    font-weight: 400;
  }
  .slidethreeoverlay:hover {
    opacity: 0.8;
  }

  .slidefour {
    width: 50vw;
    height: 30vh;
  }

  .slidefouroverlay {
    position: absolute;
    top: 60%;
    left: 8%;
    box-sizing: border-box;
    width: 7%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.9s ease;
    background-color: transparent;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidefouroverlay h1 {
    position: relative;
    right: -250%;
    top: -120%;
    opacity: 1;
    font-weight: 400;
  }
  .slidefouroverlay:hover {
    opacity: 0.8;
  }

  .slidefive {
    width: 50vw;
    height: 30vh;
  }

  /*
  .slidefiveoverlay {
    position: absolute;
    top: 80%;
    left: 8%;
    box-sizing: border-box;
    width: 7%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0.5;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidefiveoverlay h1 {
    object-fit: cover;
    position: relative;
   right: -30%;
    top: -20%;
    opacity: 1;
            font-weight: 400;

  }
  .slidefiveoverlay:hover {
    opacity: 0.6;
  }
  */

  .slidefiveoverlay {
    display: none;
  }

  .slideone img {
    opacity: 0.5;
    width: 29vh;
    height: 80vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }

  .slidetwo img {
     opacity: 0.5;
    width: 29vh;
    height: 80vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
  .slidethree img {
     opacity: 0.5;
    width: 29vh;
    height: 80vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
  .slidefour img {
     opacity: 0.5;
    width: 29vh;
    height: 80vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
    position: absolute;
  }
  /*
  .slidefive img {
    width: 29vh;
    height: 80vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
  */

  .slidefive img {
    display: none;
  }
}

/*
@media screen and (max-width: 500px) {
  .hero {
    width: 100%;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-x: scroll;
    overflow-y: hidden;
    position: absolute;
    top: -30%;
    left: 30%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: 10;
    white-space: nowrap;
    animation: fadeIn 5s;
    -webkit-overflow-scrolling: touch;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: row;
    min-width: 650px;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
    z-index: 30;
    -webkit-overflow-scrolling: touch;
  }

  .slideone {
    width: 50vw;
    height: 20vh;
  }

  .slideoneoverlay {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 70%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slideoneoverlay h1 {
    position: relative;
    right: -50%;
    top: -4%;
    opacity: 1;
  }
  .slideoneoverlay:hover {
    opacity: 0.5;
  }

  .slidetwo {
    width: 50vw;
    height: 20vh;
  }

  .slidetwooverlay {
    object-fit: cover;
    position: absolute;
    top: 16%;
    left: 1%;

    box-sizing: border-box;
    width: 100%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidetwooverlay h1 {
    position: relative;
    right: -50%;
    top: -10%;
    opacity: 1;
  }
  .slidetwooverlay:hover {
    opacity: 0.6;
  }

  .slidethree {
    width: 50vw;
    height: 20vh;
  }

  .slidethreeoverlay {
    position: absolute;
    top: 35%;
    left: 1%;
    box-sizing: border-box;
    width: 100%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidethreeoverlay h1 {
    position: relative;
    right: -40%;
    top: -17%;
    opacity: 1;
  }
  .slidethreeoverlay:hover {
    opacity: 0.8;
  }

  .slidefour {
    width: 50vw;
    height: 20vh;
  }

  .slidefouroverlay {
    position: absolute;
    top: 53%;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidefouroverlay h1 {
    position: relative;
    right: -40%;
    top: -40%;
    opacity: 1;
  }
  .slidefouroverlay:hover {
    opacity: 0.6;
  }

  .slidefive {
    width: 50vw;
    height: 20vh;
  }

  .slidefiveoverlay {
    position: absolute;
    top: 71%;
    left: 1%;
    box-sizing: border-box;
    width: 100%;
    height: 20%;
    color: #ffffff;
    font: 30px sans-serif;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.9s ease;
    background-color: #242424;
    padding-top: 100px;
    border-radius: 10px;
    font-size: 80%;
    writing-mode: vertical-lr;
  }
  .slidefiveoverlay h1 {
    object-fit: cover;
    position: relative;
    left: 40%;
    top: -2%;
    opacity: 1;
  }
  .slidefiveoverlay:hover {
    opacity: 0.6;
  }

  .slideone img {
    width: 29vh;
    height: 119vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }

  .slidetwo img {
    width: 29vh;
    height: 120vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
  .slidethree img {
    width: 29vh;
    height: 120vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
  .slidefour img {
    width: 29vh;
    height: 120vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
    position: absolute;
  }

  .slidefive img {
    width: 20vh;
    height: 120vh;
    object-fit: cover;
    overflow: hidden;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
}

*/


@media screen and (max-width: 1920px)and (min-width: 1920px) {
  .slideoneoverlay h1 {
    position: relative;
    right: -10%;
    top: -7%;
    opacity: 1;
  }
 
  .slidethreeoverlay h1 {
    position: relative;
    right: -23%;
    top: -50%;
    opacity: 1;
  }
  
  .slidefouroverlay h1 {
    position: relative;
    right: -25%;
    top: -30%;
    opacity: 1;
  }
  }

  @media screen and (max-width: 1003px) and (min-width: 1003px) {

    
   
    .slideoneoverlay h1 {
      position: relative;
      right: -17%;
      top: -15%;
      opacity: 1;
     height: 200px;
     text-align: center;
    }
   
    .slidethreeoverlay h1 {
      position: relative;
      right: -60%;
      top: -50%;
      opacity: 1;
      text-align: center;
    }
    
    .slidefouroverlay h1 {
      position: relative;
      right: -62%;
      top: -30%;
      opacity: 1;
      text-align: center;
    }
  
    }

