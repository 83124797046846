.testoIspirami {
  position: absolute;
  left: 30%;
  top: 42%;
    max-width: 500px;

  font-size: 150%;
  animation: fadeIn 6s;
  text-align: justify;
}

.titoloIspirami {
  position: absolute;
  left: 30%;
  top: 30%;
  font-size: 150%;
  animation: fadeIn 5s;
}

.boxcont {
  position: absolute;
  left: 70%;
  top: 20%;
  max-width: 20%;
  animation: fadeIn 5s;
}


@media screen and (max-width: 500px) {
  .titoloIspirami {
    position: absolute;
    left: 18%;
    top: 100%;
    font-size: 150%;
    animation: fadeIn 5s;
  }

  .testoIspirami {
    position: absolute;
    left: 18%;
    top: 200%;
       max-width: 300px;
    font-size: 120%;
    animation: fadeIn 6s;
    text-align: justify;
  }


.boxcont {
  position: absolute;
  left: 50%;
  top: 20%;
  max-width: 20%;
  animation: fadeIn 5s;
}

  }

  @media screen and (max-width: 1366px) and (min-width: 1366px) {
   
    .titoloIspirami {
      position: absolute;
      left: 35%;
      top: 10%;
      font-size: 150%;
      animation: fadeIn 5s;
    }
    .testoIspirami {
      position: absolute;
      left: 35%;
      top: 30%;
      font-size: 120%;
      animation: fadeIn 6s;
      text-align: justify;
    }
  
    .boxcont {
      position: absolute;
      left: 65%;
      top: 5%;
      max-width: 20%;
      animation: fadeIn 5s;
    }
    }


    @media screen and (max-width: 1003px) and (min-width: 1003px) {
   
      .titoloIspirami {
        position: absolute;
        left: 35%;
        top: 5%;
        font-size: 150%;
        animation: fadeIn 5s;
      }
      .testoIspirami {
        position: absolute;
        left: 35%;
        top: 14%;
        font-size: 120%;
        animation: fadeIn 6s;
        text-align: justify;
      }
    
      .boxcont {
        position: absolute;
        left: 65%;
        top: -7%;
        max-width: 19%;
        animation: fadeIn 5s;
      }
    
      }
