.textcontainer {
  width: 70px;
  height: 500px;
  padding-top: 0.5%;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 1200px;
}

.titolobio {
  font-size: 150%;
  opacity: 90%;
  position: absolute;
  color: #ffffff;
  top: 10%;
  left: -900%;
  animation: fadIn 2s;
}

.testobio {
  padding-top: 2px;
  left: -900%;
  top: 30%;
  font-size: 70%;
  color: #ffffff;
  opacity: 120%;
  position: absolute;
  animation: fadeIn 5s;
  font-weight: 100;
  opacity: 1;
    text-align: justify;

}
    .fotobio img {
    position: absolute;
     width: 400%;
    object-fit: cover;
    animation: fadeIn 10s;
    
    left: 140%;
    top: 33%;
  
  }

.titolostat {
  font-size: 150%;
  opacity: 90%;
  position: absolute;
  color: #ffffff;
  top: 10%;
  left: -900%;
  animation: fadIn 2s;
}

.testostat {
  padding-top: 2px;
  left: -900%;
  top: 30%;
  font-size: 70%;
  color: #ffffff;
  opacity: 120%;
  position: absolute;
  animation: fadeIn 5s;
  font-weight: 100;

    text-align: justify;

}
   

@media screen and (max-width: 500px) {
  .textcontainer {
    position: absolute;
    width: 400px;

    left: 15%;
  }

  .titolobio {
    position: absolute;
    top: 50%;
    left: -10%;
    animation: fadeIn 3s;
  }
  .testobio {
    position: absolute;
    font-size: 50%;
    animation: fadeIn 10s;
    left: 30%;
    top: 5%;
    font-weight: 100;
    text-align: justify;
    opacity: 1;
  }
   .fotobio img {
    position: absolute;
     width: 70%;
    object-fit: cover;
    animation: fadeIn 10s;
    left: 120%;
    top: 20%;
  
  }
 .titolostat {
    position: absolute;
    top: 50%;
    left: -10%;
    animation: fadeIn 3s;
   font-size: 90%;
  }
  .testostat {
    position: absolute;
    font-size: 50%;
    animation: fadeIn 10s;
   
    font-weight: 100;
    text-align: justify;
    opacity: 120%;
    left: 35%;
    top: 30%;
  }
 
}
