.wrapper {
  display: flex;
  flex-direction: row;
 width: 2000px;
 

  scroll-behavior: smooth;

  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}

.outer-wrapper {
  width: 100vh;
  height: 100vw;
  
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: auto;
  overflow-x: scroll;
  position: absolute;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

 ::-webkit-scrollbar {
    display: none;
  }




