@import url('https://fonts.cdnfonts.com/css/tt-commons');

* {
  box-sizing: border-box;
  margin: none;
  padding: none;
 font-family: "TT Commons", sans-serif;
   font-weight: 100;

}

body {
  background: #212121;
  overflox-y: hidden !important;
  overflow-x: auto;
}

h1,
h4,
p {
  color: #fff;
  text-decoration: none;
  opacity: 1;
    font-weight: 200;

}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 2rem;
  background: none;
  color: #ffffff;
  border: none;
  font-weight: 100;
  cursor: pointer;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: #fff;
}
.diritti {
  position: absolute;
  right: 1%;
  top: 30%;
}

.diritti img {
  width: 100%;
  transform: rotate(180deg);
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.inizio {
  opacity: 1;
  animation: fade 2s linear;
}
