.indicazione {
  position: absolute;
  left: 40%;
  top: 60%;
  font-size: 70%;
}
.indicazione img {
  width: 70%;
  opacity: 1;
  animation: fade 2s linear;
}

.indicazione h4 {
  opacity: 1;
  font-size: 200%;
  animation: fadeIn 5s;
  font-weight: 100;
}

label {
  color: #fff;
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;
  margin: auto;
  max-width: 600px;
  animation: fadeIn 10s;
}

form input,
form textarea {
  margin-bottom: 1rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  background-color: #212121;
  border-color: #ffffff;
  color: #f4f4f4;
}

.titolocontatti {
  font-size: 150%;
  position: absolute;
  left: 40%;
  top: 35%;
}

.titolocontatti img {
}

.invia {
  padding: 12px 32px;
  font-size: 1rem;
  background: none;
  color: #ffffff;
  border-color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  border-radius: 50%;
}

.invia:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}
/*
.insta {
  position: absolute;
  right: 12%;
  top: 50%;
  font-size: 200%;
}
*/

.instacliccato {
  font-size: 110%;
}

/*
.instacliccato:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  background-size: 1%;
  
}


.instacliccato::before {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.instacliccato:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
*/
a.instacliccato {
  color: #ffffff;
  position: relative;
  text-decoration: none;
}

a.instacliccato::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

a.instacliccato:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

a.Nav1 {
  color: #ffffff;
  position: relative;
  text-decoration: none;
}

a.Nav1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

a.Nav1:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}



@media screen and (max-width: 500px) {


.titolocontatti {
  font-size: 150%;
  position: absolute;
  left: 20%;
  top: 150%;
}
.indicazione {
  position: absolute;
  left: 20%;
  top: 300%;
  font-size: 70%;
}

 
  }
