@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.diranimazione img {
  width: 5%;
  position: absolute;
  left: 18%;
  top: 60%;
  animation: flickerAnimation 3s infinite;
}
.diranimazione p {
  position: absolute;
  top: 105%;
  left: 5%;
  color: #ffffff;
}
.diranimazione h4 {
  position: absolute;
  top: 70%;
  left: 18%;
  color: #ffffff;
 
  animation: flickerAnimation 3s infinite;
}
.header {
  display: block;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
 z-index: -1;
}

.icona {
  display: block;
  max-width: 15%;
}

.header-bg {
  background-color: #212121;
  transition: 0.5s;
}
.nav-menu {
  display: block;
  padding-block: 3rem;
  padding-inline: 5rem;
}

.nav-menu li {
  padding: 1rem;
}

.nav-menu li a {
  font-size: 1.5rem;
  font-family: FCaslon 42 ITC;
}

.hamburger {
  display: none;
}

@media screen and (max-height: 768px) {
  .diranimazione img {
    width: 7%;
    position: absolute;
    left: 17%;
    top: 60%;
    animation: flickerAnimation 3s infinite;
  }
  .diranimazione p {
    position: absolute;
    top: 96%;
    left: 5%;
    color: #ffffff;
  }
  
  .header {
    display: block;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .icona {
    display: block;
    max-width: 10%;
  }

  .header-bg {
    background-color: #212121;
    transition: 0.5s;
  }
  .nav-menu {
    display: block;
    padding-block: 3rem;
    padding-inline: 5rem;
  }

  .nav-menu li {
    padding: 1rem;
  }

  .nav-menu li a {
    font-size: 2rem;
    font-family: FCaslon 42 ITC;
  }

  .hamburger {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #212121;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.5s;
  }

  .icona {
    display: block;
    max-width: 5%;
  }

  .nav-menu {
    top: 130%;

    background-color: #212121;
  }

  .diranimazione p {
    z-index: -5;
    top: 700%;
    left: 1%;
  }
  .diranimazione img {
    width: 5%;
    position: absolute;
    left: 2%;
    top: 400%;
    animation: flickerAnimation 3s infinite;
    z-index: -10;
  }

   .diranimazione h4 {
    position: absolute;
    top: 440%;
    left: 2%;
    color: #ffffff;
    z-index: -5;
    animation: flickerAnimation 3s infinite;
  }
  .hamburger {
    display: initial;
    position: absolute;
    top: 150%;
    left: 1%;
  }
  .nav-menu.active {
    left: 0;
  }

  .nav-menu li {
    padding: 1rem 0;
  }

  .nav-menu li a {
    font-size: 2rem;
  }
}
